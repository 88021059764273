import { instance } from './index';

export const aircraftsAPI = {
  searchAircraftTypes (data) {
    return instance.get(`aircrafts/types/${data.query}`)
  },
  searchAircraftModels (data) {
    return instance.get(`aircrafts/models/${data.aircraftTypeId}/${data.query}`)
  },
  searchAircraftModelsWithoutType (data) {
    return instance.get(`aircrafts/models/${data.query}`)
  },
  getAircrafts () {
    return instance.get('aircrafts/list')
  },
  getCurrentAircraft: (data) => instance.get(`aircrafts/${data}`),
  createAircraft (data) {
    return instance.post('aircrafts/save', {
      maxPassengers: data.MaxPassengers,
      aircraftId: data.aircraftId,
      argusSafetyRating: data.argusSafetyRating,
      bookableDemo: data.bookableDemo,
      isFloatingFleet: data.isFloatingFleet,
      cargoCapability: data.cargoCapability,
      documents: data.documents,
      fuelBurn: data.fuelBurn,
      homebaseId: data.homebaseId,
      hoursFlown: data.hoursFlown,
      images: data.images,
      lastExtRefurbish: data.lastExtRefurbish,
      lastIntRefurbish: data.lastIntRefurbish,
      manufactureYear: data.manufactureYear,
      modelId: data.modelId,
      numberOfTelevision: data.numberOfTelevision,
      pricePerHour: data.pricePerHour,
      emptyLegsPricePerHour: data.emptyLegsPricePerHour,
      minimumAcceptablePricePerTrip: data.minimumAcceptablePricePerTrip,
      minimumAcceptablePricePerEmptyLeg: data.minimumAcceptablePricePerEmptyLeg,
      range: data.range,
      speed: data.speed,
      tailNumber: data.tailNumber,
      typeId: data.typeId,
      wifi: data.wifi,
      wyvernSafetyRating: data.wyvernSafetyRating,
      sellAsCharterSeat: data.isSpacesAvailable,
      spaces: data.spaces,
    })
  },
  getCurrentProviderCalendar () {
    return instance.get('providerCalendar/calendar')
  },
  getProviderCalendar (aircraftProviderId) {
    return instance.get(`providerCalendar/calendar/${aircraftProviderId}`)
  },
  addAircraftAvailability (data) {
    return instance.post('aircrafts/availability/save', data);
  },
  changeAvailabilityInSchedulerUi (data) {
    return instance.post('providerCalendar/changeavailabilityinschedulerui', data);
  },
  changeEmptyLegInSchedulerUi (data) {
    return instance.post('providerCalendar/changeemptyleginschedulerui', data);
  },
  addEmptyLegs (data) {
    return instance.post('emptylegs/save', data);
  },
  removeAircraftAvailability (data) {
    return instance.patch('aircrafts/availability/remove-availability', data);
  },
  removeAircraftAvailabilityNew (data) {
    return instance.patch('aircrafts/availability/remove', data);
  },
  removeImage (imageId) {
    return instance.patch(`aircrafts/removeImage/${imageId}`);
  },
  removeEmptyLegs (data) {
    return instance.patch('emptylegs/remove-availability', data);
  },
  getAvailability (route) {
    return instance.get(`aircrafts/availability/${route}`)
  },
  getEmptyLegs (route) {
    return instance.get(`emptylegs/${route}`)
  },
  aircraftUploadPhoto (data) {
    return instance.post('aircrafts/uploadphoto', data)
  },
  aircraftUploadDocuments (data) {
    return instance.post('aircrafts/uploaddocument', data)
  },
  aircraftAvailabilityRemove (data) {
    return instance.patch('aircrafts/remove', { aircraftId: data.aircraftId })
  },
  getShortPlaneInfo (id) {
    return instance.get(`aircrafts/getShortDetailsAircraft/${id}`)
  },
}
