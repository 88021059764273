import {flyAllianceAPI} from '@/api/flyAlliance';
import {locationsAPI} from '@/api/locations';
import {aircraftsAPI} from '@/api/aircrafts';

const state = () => ({
    fixPriceRouteList: [],
    flyAllianceSearchResults: {
      aircraftModel: [],
      firstAirport: [],
      secondAirport: [],
    },
})

const mutations = {
    getFixPriceRouteList (state, data) {
        state.fixPriceRouteList = data
    },
    setFlyAllianceSearchResults (state, {result, data}) {
      if (data.type === 'aircraftModel') {
        state.flyAllianceSearchResults.aircraftModel = result.data
      } else if (data.type === 'firstAirport') {
        state.flyAllianceSearchResults.firstAirport = result.data
      } else {
        state.flyAllianceSearchResults.secondAirport = result.data
      }
    },
    deleteFlyAllianceSearchResults (state, data) {
      if (data.type === 'aircraftModel') {
        state.flyAllianceSearchResults.aircraftModel = []
      } else if (data.type === 'firstAirport') {
        state.flyAllianceSearchResults.firstAirport = []
      } else {
        state.flyAllianceSearchResults.secondAirport = []
      }
    },
}

const getters = {
  shortListAircraftModels: state => count => {
    return state.flyAllianceSearchResults.aircraftModel.filter((elem, id) => {
      if (id <= count) {
        return elem;
      }
    });
  },
  shortListFirstAirport: state => count => {
    return state.flyAllianceSearchResults.firstAirport.filter((elem, id) => {
      if (id <= count) {
        return elem;
      }
    });
  },
  shortListSecondAirport: state => count => {
    return state.flyAllianceSearchResults.secondAirport.filter((elem, id) => {
      if (id <= count) {
        return elem;
      }
    });
  },
};

const actions = {
    async getFixPriceRoutes ({commit}) {
        try {
          const result = await flyAllianceAPI.getFixPriceRoute()
          if (result.data.isSuccessfull) {
            commit('getFixPriceRouteList', result.data.item)
          }
        } catch (e) {
          console.log('routes not found');
        }
    },
    async flyAllianceSearchResults ({commit, dispatch}, data) {
      try {
        let result
        if (data.type === 'aircraftModel') {
          result = await aircraftsAPI.searchAircraftModelsWithoutType(data)
        } else {
          result = await locationsAPI.searchLocations(data);
        }
        commit('setFlyAllianceSearchResults', {result, data})
      } catch (e) {
        commit('deleteSearchResults', data);
      }
    },
    async createFixPriceRoute ({commit, dispatch}, data) {
      try {
        await flyAllianceAPI.createFixPriceRoute(data)
        return true
      } catch (e) {
        console.log('creating failed');
        return false
      }
    },
}

export default {
    state,
    mutations,
    getters,
    actions,
}