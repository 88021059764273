import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store/index';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: () => import('@/layouts/LoginLayout.vue'),
    // beforeenter causing load time - taken out, use diff lifecycle method
    async beforeEnter (to, from, next) {
      const check = await store.dispatch('checkLogin')
      if (check) {
        const type = store.state.auth.user.type;
        switch (type) {
          case 1:
            if (from.path === '/search-results' || from.path.startsWith('/booking/')) {
              next('/map-search');
            } else {
              window.location.replace(process.env.VUE_APP_NEW_LANDING)
            }
            break;
          case 2:
            next('/provider-dashboard');
            break;
          case 4:
            next('/admin');
            break;
          default:
            window.location.replace(process.env.VUE_APP_NEW_LANDING)
            return false;
        }
      } else {
        window.location.replace(process.env.VUE_APP_NEW_LANDING)
      }
    },
  },
  {
    path: '/app/main',
    async beforeEnter (to, from, next) {
      next('/');
    },
  },
  {
    path: '/app/Verify/:id',
    component: () => import('@/layouts/LoginLayout.vue'),
    async beforeEnter (to, from, next) {
      const verificationResult = await store.dispatch('verify', to.params.id)
      console.log(verificationResult);
      window.location.replace(`${process.env.VUE_APP_NEW_LANDING}?verified=true`)
    },
  },
  {
    path: '/app/ForgotPassword/:id',
    component: () => import('@/layouts/resetPasswordLayout.vue'),
  },
  {
    path: '/map-search',
    props: {header: 'dashboard'},
    meta: {layout: 'common'},
    component: () => import('@/views/main/mapBlock/mapBlock.vue'),
    async beforeEnter (to, from, next) {
      const check = await store.dispatch('checkLogin')
      if (check) {
        const type = store.state.auth.user.type;
        if (type === 1) {
          next()
        } else if (type === 2) {
          next('/provider-dashboard')
        }
      } else {
        next('/')
      }
    },
  },
  {
    path: '/manually-request',
    props: {header: 'dashboard'},
    meta: {layout: 'common'},
    component: () => import('@/views/main/ManuallyRequest/ManuallyRequest.vue'),
    async beforeEnter (to, from, next) {
      const check = await store.dispatch('checkLogin')
      if (check) {
        next()
        // console.log(store.state.search.searchRequest.arrivalId)
        // if (!store.state.search.searchRequest.arrivalId) {
        //   next('/map-search')
        // }
      } else {
        next('/')
      }
    },
  },
  {
    path: '/search-results',
    name: 'search-results',
    props: { header: 'search results' },
    meta: { layout: 'common' },
    component: () => import('@/views/main/SearchResults/SearchResults.vue'),
    async beforeEnter (to, from, next) {
      const check = await store.dispatch('checkLogin')

      if (check) {
        next()

        // if (!store.state.search.searchRequest.arrivalId) {
        //   next('/map-search')
        // }
      } else {
        next('/')
      }
    },
  },
  {
    path: '/featured-flight',
    props: {header: 'Dashboard'},
    meta: {layout: 'common'},
    component: () => import('@/views/main/SearchResults/FeaturedFlight.vue'),
    async beforeEnter (to, from, next) {
      const check = await store.dispatch('checkLogin')
      if (check) {
        next()

        if (!store.state.search.featuredFlightToBook.emptyLegId) {
          next('/map-search')
        }
      } else {
        next('/')
      }
    },
  },
  {
    path: '/aircraft-detail',
    props: {header: 'Aircraft detail'},
    meta: {layout: 'common'},
    component: () => import('@/views/main/AircraftDetail/AircraftDetail.vue'),
  },
  {
    path: '/aircraft-detailbid',
    props: {header: 'Aircraft detail'},
    meta: {layout: 'common'},
    component: () => import('@/views/main/AircraftDetail/AircraftDetailBid.vue'),
  },
  {
    path: '/auction-bidding',
    props: {header: 'Auction Bidding'},
    meta: {layout: 'common'},
    component: () => import('@/views/main/AircraftDetail/AuctionBidding.vue'),
  },
  {
    path: '/booking',
    component: () => import('@/layouts/BookingLayout.vue'),
    async beforeEnter (to, from, next) {
      const check = await store.dispatch('checkLogin')
      if (check) {
        next()

        const fromNewLanding = !!to.query.bookingNumber
        if (fromNewLanding) {
          await store.dispatch('getBookingInfoByNumber', to.query.bookingNumber);
        }

        if (!store.state.bookingFromSearch.bookingNo) {
          next('/map-search')
        }
      } else {
        next('/')
      }
    },
    children: [
      {
        path: 'flights',
        props: {header: 'booking'},
        component: () => import('@/views/booking/Flight/Flight.vue'),
      },
      {
        path: 'aircraft',
        props: {header: 'booking'},
        component: () => import('@/views/booking/Aircraft/Aircraft.vue'),
      },
      {
        path: 'travelers',
        props: {header: 'booking'},
        component: () => import('@/views/booking/Travelers/Travelers.vue'),
      },
      {
        path: 'terms',
        props: {header: 'booking'},
        component: () => import('@/views/booking/Terms/Terms.vue'),
      },
      {
        path: 'fly-green',
        props: {header: 'booking'},
        component: () => import('@/views/booking/FlyGreen/FlyGreen.vue'),
      },
      {
        path: 'payment',
        props: {header: 'booking'},
        component: () => import('@/views/booking/Payment/Payment'),
      },
      {
        path: 'confirmation',
        props: {header: 'booking'},
        component: () => import('@/views/booking/Confirmation/Confirmation'),
      },
    ],
  },
  {
    path: '/dashboard',
    props: {
      header: 'Dashboard',
      dashboardType: 'flyer',
    },
    component: () => import('@/views/dashboard/DashboardContainer.vue'),
    async beforeEnter (to, from, next) {
      const check = await store.dispatch('checkLogin')

      if (check) {
        const type = store.state.auth.user.type;
        if (type === 1) {
          next()
        } else if (type === 2) {
          next('/provider-dashboard')
        } else {
          next('/')
        }
      } else {
        next('/')
      }
    },
    children: [
      {
        meta: {layout: 'dashboard'},
        props: {subtitle: null},
        path: '',
        component: () => import('@/views/dashboard/flyer/DashboardFlyer/DashboardFlyer'),
      },
      {
        meta: {layout: 'dashboard'},
        props: {
          subtitle: 'profile',
          dashboardType: 'flyer',
        },
        path: 'profile',
        component: () => import('@/views/dashboard/flyer/Profile/Profile'),
      },
      {
        meta: {layout: 'dashboard'},
        props: {
          subtitle: 'members',
          dashboardType: 'flyer',
        },
        path: 'members',
        component: () => import('@/views/dashboard/flyer/Members/Members'),
      },
      {
        meta: {layout: 'dashboard'},
        props: {
          subtitle: 'subscriptions',
          dashboardType: 'flyer',
        },
        path: 'subscriptions',
        component: () => import('@/views/dashboard/flyer/Subscriptions/Subscriptions'),
      },
      {
        meta: {layout: 'dashboard'},
        props: {
          subtitle: 'booking',
          dashboardType: 'flyer',
        },
        path: 'booking',
        component: () => import('@/views/dashboard/flyer/BookingList/BookingList'),
      },
      {
        meta: {layout: 'dashboard'},
        props: {
          subtitle: 'flights',
          dashboardType: 'flyer',
        },
        path: 'flights',
        component: () => import('@/views/dashboard/flyer/Flights/Flights'),
      },
      {
        meta: {layout: 'dashboard'},
        props: {
          subtitle: 'flycalendar',
          dashboardType: 'flyer',
        },
        path: 'flycalendar',
        component: () => import('@/views/dashboard/flyer/FlyCalendar/FlyCalendar'),
      },
      {
        meta: {layout: 'dashboard'},
        props: {
          subtitle: 'manual request',
          dashboardType: 'flyer',
        },
        path: 'manual-request',
        component: () => import('@/views/dashboard/flyer/ManualRequests/ManualRequests'),
      },
      {
        meta: {layout: 'dashboard'},
        props: {
          subtitle: 'settings & security',
          dashboardType: 'flyer',
        },
        path: 'settings',
        component: () => import('@/views/dashboard/flyer/Settings/Settings'),
      },
      {
        meta: {layout: 'dashboard'},
        props: {
          subtitle: 'passenger ID',
          dashboardType: 'flyer',
        },
        path: 'passenger-id',
        component: () => import('@/views/dashboard/flyer/PassengerId/PassengerId'),
      },
      {
        meta: {layout: 'dashboard'},
        props: {
          subtitle: 'flyRewards',
          dashboardType: 'flyer',
        },
        path: 'flyrewards',
        component: () => import('@/views/dashboard/flyer/FlyRewards/FlyRewards'),
      },
      {
        meta: {layout: 'dashboard'},
        props: {
          subtitle: 'payment information',
          dashboardType: 'flyer',
        },
        path: 'payment',
        component: () => import('@/views/dashboard/flyer/Payment/Payment'),
      },
      {
        meta: {layout: 'dashboard'},
        props: {
          subtitle: 'route alerts',
          dashboardType: 'flyer',
        },
        path: 'alerts',
        component: () => import('@/views/dashboard/flyer/Alerts/Alerts'),
      },
      {
        meta: {layout: 'dashboard'},
        props: {
          subtitle: 'auctions (bids)',
          dashboardType: 'flyer',
        },
        path: 'auctions',
        component: () => import('@/views/dashboard/flyer/Auctions/Auctions'),
      },
      {
        meta: {layout: 'dashboard'},
        props: {
          subtitle: 'fly empty',
          dashboardType: 'flyer',
        },
        path: 'requests',
        component: () => import('@/views/dashboard/common/Requests/Requests'),
      },
      {
        meta: {layout: 'dashboard'},
        props: {
          subtitle: 'sell spaces',
          dashboardType: 'flyer',
        },
        path: 'seats',
        component: () => import('@/views/dashboard/flyer/Seats/Seats'),
      },
      {
        meta: {layout: 'dashboard'},
        props: {
          subtitle: 'messages',
          dashboardType: 'flyer',
        },
        path: 'messages',
        component: () => import('@/views/dashboard/common/Messages/Messages'),
      },
    ],
  },
  {
    path: '/provider-dashboard',
    props: {
      header: 'Dashboard',
      dashboardType: 'provider',
    },
    component: () => import('@/views/dashboard/DashboardContainer.vue'),
    async beforeEnter (to, from, next) {
      const check = await store.dispatch('checkLogin')

      if (check) {
        const type = store.state.auth.user.type;
        if (type === 2) {
          next()
        } else if (type === 1) {
          next('/')
        } else {
          next('/')
        }
      } else {
        next('/')
      }
    },
    children: [
      {
        meta: {layout: 'dashboard'},
        props: {subtitle: null},
        path: '',
        component: () => import('@/views/dashboard/provider/DashboardProvider/DashboardProvider'),
      },
      {
        meta: {layout: 'dashboard'},
        props: {
          subtitle: 'profile',
          dashboardType: 'provider',
        },
        path: 'profile',
        component: () => import('@/views/dashboard/flyer/Profile/Profile'),
      },
      {
        meta: {layout: 'dashboard'},
        props: {
          subtitle: 'settings & security',
          dashboardType: 'flyer',
        },
        path: 'settings',
        component: () => import('@/views/dashboard/flyer/Settings/Settings'),
      },
      {
        meta: {layout: 'dashboard'},
        props: {
          subtitle: 'personnel',
          dashboardType: 'provider',
        },
        path: 'personnel',
        component: () => import('@/views/dashboard/provider/Personnel/Personnel'),
      },
      {
        meta: {layout: 'dashboard'},
        props: {
          subtitle: 'Automated Scheduler',
          dashboardType: 'provider',
        },
        path: 'automated-scheduler',
        component: () => import('@/views/dashboard/provider/AutomatedScheduler/AutomatedScheduler'),
      },
      {
        meta: {layout: 'dashboard'},
        props: {
          subtitle: 'fleet',
          dashboardType: 'provider',
        },
        path: 'fleet',
        component: () => import('@/views/dashboard/common/Fleet/Fleet'),
      },
      {
        meta: {layout: 'dashboard'},
        props: {
          subtitle: 'fleet',
          dashboardType: 'provider',
          fleetType: 'detail',
        },
        path: 'fleet/:id',
        component: () => import('@/views/dashboard/common/DetailAircraft/DetailAircraft'),
      },
      {
        meta: {layout: 'dashboard'},
        props: {
          subtitle: 'fleet',
          dashboardType: 'provider',
          fleetType: 'new',
        },
        path: 'new-aircraft',
        component: () => import('@/views/dashboard/common/DetailAircraft/DetailAircraft'),
      },
      {
        meta: {layout: 'dashboard'},
        props: {
          dashboardType: 'provider',
        },
        path: 'fixed-price-routes',
        component: () => import('@/views/dashboard/common/FixPriceRoutes/FixPriceRoutes'),
      },
      {
        meta: {layout: 'dashboard'},
        props: {
          dashboardType: 'provider',
          fixPriceRouteType: 'detail',
        },
        path: 'fixed-price-routes/:id',
        component: () => import('@/views/dashboard/common/DetailFixPriceRoute/DetailFixPriceRoute'),
      },
      {
        meta: {layout: 'dashboard'},
        props: {
          dashboardType: 'provider',
          fixPriceRouteType: 'new',
        },
        path: 'new-fixed-price-route',
        component: () => import('@/views/dashboard/common/DetailFixPriceRoute/DetailFixPriceRoute'),
      },
      {
        meta: {layout: 'dashboard'},
        props: {
          subtitle: 'charter availability uploads',
          dashboardType: 'provider',
          uploadsType: 'charter',
        },
        path: 'charter-uploads',
        component: () => import('@/views/dashboard/provider/AvailabilitiesList/CharterAvailabilitiesList'),
        // component: () => import('@/views/dashboard/common/CharterUploads/CharterUploads')
      },
      {
        meta: {layout: 'dashboard'},
        props: {
          subtitle: 'charter availability uploads',
          dashboardType: 'provider',
          uploadsType: 'charter',
        },
        path: 'charter-uploads/:id',
        component: () => import('@/views/dashboard/common/CharterUploads/CharterUploads'),
      },
      {
        meta: {layout: 'dashboard'},
        props: {
          subtitle: 'flight availability uploads',
          dashboardType: 'provider',
          uploadsType: 'flight',
        },
        path: 'flight-uploads',
        component: () => import('@/views/dashboard/provider/AvailabilitiesList/FlightAvailabilitiesList'),
      },
      {
        meta: {layout: 'dashboard'},
        props: {
          subtitle: 'flight availability uploads',
          dashboardType: 'provider',
          uploadsType: 'flight',
        },
        path: 'flight-uploads/:id',
        component: () => import('@/views/dashboard/common/FlightUploads/FlightUploads'),
      },
      {
        meta: {layout: 'dashboard'},
        props: {
          subtitle: 'booking',
          dashboardType: 'provider',
        },
        path: 'booking',
        component: () => import('@/views/dashboard/flyer/BookingList/BookingList'),
      },
      {
        meta: {layout: 'dashboard'},
        props: {
          subtitle: 'booking confirmations',
          dashboardType: 'provider',
        },
        path: 'booking-confirmations',
        component: () => import('@/views/dashboard/provider/BookingConfirmation/BookingConfirmation'),
      },
      {
        meta: {layout: 'dashboard'},
        props: {
          subtitle: 'flights',
          dashboardType: 'provider',
        },
        path: 'flights',
        component: () => import('@/views/dashboard/provider/FlightsProvider/FlightsProvider'),
      },
      {
        meta: {layout: 'dashboard'},
        props: {
          subtitle: 'flights',
          dashboardType: 'provider',
        },
        path: 'flights/detail',
        component: () => import('@/views/dashboard/provider/FlightsProvider/FlightsProviderDetail'),
      },
      {
        meta: {layout: 'dashboard'},
        props: {
          subtitle: 'flyRewards',
          dashboardType: 'provider',
        },
        path: 'flyrewards',
        component: () => import('@/views/dashboard/flyer/FlyRewards/FlyRewards'),
      },
      {
        meta: {layout: 'dashboard'},
        props: {
          subtitle: 'payment information',
          dashboardType: 'provider',
        },
        path: 'payment',
        component: () => import('@/views/dashboard/flyer/Payment/Payment'),
      },
      {
        meta: {layout: 'dashboard'},
        props: {
          subtitle: 'messages',
          dashboardType: 'provider',
        },
        path: 'messages',
        component: () => import('@/views/dashboard/common/Messages/Messages'),
      },
      {
        meta: {layout: 'dashboard'},
        props: {
          subtitle: 'requests',
          dashboardType: 'provider',
        },
        path: 'requests',
        component: () => import('@/views/dashboard/common/Requests/Requests'),
      },
      {
        meta: {layout: 'dashboard'},
        props: {
          subtitle: 'auctions',
          dashboardType: 'provider',
        },
        path: 'auctions',
        component: () => import('@/views/dashboard/provider/Auctions/Auctions'),
      },
    ],
  },
  {
    path: '/admin',
    props: {
      header: 'Dashboard',
      dashboardType: 'admin',
    },
    component: () => import('@/views/dashboard/DashboardContainer.vue'),
    async beforeEnter (to, from, next) {
      const check = await store.dispatch('checkLogin')

      if (check) {
        const type = store.state.auth.user.type;
        if (type === 4) {
          next()
        } else if (type === 2) {
          next('/')
        } else {
          next('/')
        }
      } else {
        next('/')
      }
    },
    children: [
      {
        meta: {layout: 'dashboard'},
        props: {subtitle: null},
        path: '',
        component: () => import('@/views/dashboard/admin/DashboardAdmin/DashboardAdmin'),
      },
      {
        meta: {layout: 'dashboard'},
        props: {
          subtitle: 'profile',
          dashboardType: 'admin',
        },
        path: 'profile',
        component: () => import('@/views/dashboard/flyer/Profile/Profile'),
      },
      {
        meta: {layout: 'dashboard'},
        props: {
          subtitle: 'settings & security',
          dashboardType: 'admin',
        },
        path: 'settings',
        component: () => import('@/views/dashboard/flyer/Settings/Settings'),
      },
      {
        meta: {layout: 'dashboard'},
        props: {
          subtitle: 'fly empty',
          dashboardType: 'admin',
        },
        path: 'request-empty',
        component: () => import('@/views/dashboard/admin/RequestEmpty/RequestEmpty'),
      },
      {
        meta: {layout: 'dashboard'},
        props: {
          subtitle: 'network activity',
          dashboardType: 'admin',
        },
        path: 'network',
        component: () => import('@/views/dashboard/admin/Network/Network'),
      },
      {
        meta: {layout: 'dashboard'},
        props: {
          subtitle: 'aircraft provider requests',
          dashboardType: 'admin',
        },
        path: 'provider-sign-ups',
        component: () => import('@/views/dashboard/admin/SignUpDashboard/SignUpDashboard'),
      },
      {
        meta: {layout: 'dashboard'},
        props: {
          subtitle: 'requests',
          dashboardType: 'admin',
        },
        path: 'requests',
        component: () => import('@/views/dashboard/common/Requests/Requests'),
      },
      {
        meta: {layout: 'dashboard'},
        props: {
          subtitle: 'offset purchases',
          dashboardType: 'admin',
        },
        path: 'offsets',
        component: () => import('@/views/dashboard/admin/OffsetPurchases/OffsetPurchases'),
      },
      {
        meta: {layout: 'dashboard'},
        props: {
          subtitle: 'route alerts',
          dashboardType: 'admin',
        },
        path: 'alerts',
        component: () => import('@/views/dashboard/flyer/Alerts/Alerts'),
      },
      {
        meta: {layout: 'dashboard'},
        props: {
          subtitle: 'featured flights',
          dashboardType: 'admin',
        },
        path: 'featured',
        component: () => import('@/views/dashboard/admin/FeaturedFlights/FeaturedFlights'),
      },
      {
        meta: {layout: 'dashboard'},
        props: {
          subtitle: 'auctions',
          dashboardType: 'admin',
        },
        path: 'auction',
        component: () => import('@/views/dashboard/admin/AuctionBids/AuctionBids'),
      },
      {
        meta: {layout: 'dashboard'},
        props: {
          subtitle: 'flight requests',
          dashboardType: 'admin',
        },
        path: 'flights',
        component: () => import('@/views/dashboard/admin/FlightsAdmin/FlightsAdmin'),
      },
      {
        meta: {layout: 'dashboard'},
        props: {
          subtitle: 'Argus statistic',
          dashboardType: 'admin',
        },
        path: 'argus-statistic',
        component: () => import('@/views/dashboard/admin/ArgusStatistic/ArgusStatistic'),
      },
      {
        meta: {layout: 'dashboard'},
        props: {
          subtitle: 'Newsletter Generator',
          dashboardType: 'admin',
        },
        path: 'newsletter-generator',
        component: () => import('@/views/dashboard/admin/NewsletterJsonGenerator/NewsletterJsonGenerator'),
      },
      {
        meta: {layout: 'dashboard'},
        props: {
          subtitle: 'Shortcode Generator',
          dashboardType: 'admin',
        },
        path: 'shortcode-generator',
        component: () => import('@/views/dashboard/admin/ShortcodeGenerator/ShortcodeGenerator'),
      },
      {
        meta: {layout: 'dashboard'},
        props: {
          subtitle: 'Promo codes Generator',
          dashboardType: 'admin',
        },
        path: 'promo-codes-generator',
        component: () => import('@/views/dashboard/admin/PromoCodesGenerator/PromoCodesGenerator'),
      },
      {
        meta: {layout: 'dashboard'},
        props: {
          subtitle: 'Users subscriptions',
          dashboardType: 'admin',
        },
        path: 'users-subscriptions',
        component: () => import('@/views/dashboard/admin/UsersSubscriptions/UsersSubscriptions'),
      },
      {
        meta: {layout: 'dashboard'},
        props: {
          subtitle: 'Gifts generator',
          dashboardType: 'admin',
        },
        path: 'gifts-generator',
        component: () => import('@/views/dashboard/admin/GiftsGenerator/GiftsGenerator'),
      },
      {
        meta: {layout: 'dashboard'},
        props: {
          subtitle: 'Remove unsubscribed',
          dashboardType: 'admin',
        },
        path: 'remove-unsubscribed',
        component: () => import('@/views/dashboard/admin/RemoveUnsubscribed/RemoveUnsubscribed'),
      },
      {
        meta: {layout: 'dashboard'},
        props: {
          subtitle: 'TFR Sign ups',
          dashboardType: 'admin',
        },
        path: 'tfr-sign-ups',
        component: () => import('@/views/dashboard/admin/TfrSignUps/TfrSignUps'),
      },
      {
        meta: {layout: 'dashboard'},
        props: {
          subtitle: 'Get all feed',
          dashboardType: 'admin',
        },
        path: 'get-all-feed',
        component: () => import('@/views/dashboard/admin/GetAllFeed/GetAllFeed'),
      },
      {
        meta: {layout: 'dashboard'},
        props: {
          subtitle: 'reservations',
          dashboardType: 'admin',
        },
        path: 'reservations',
        component: () => import('@/views/dashboard/flyer/BookingList/BookingList'),
      },
      {
        meta: {layout: 'dashboard'},
        props: {
          subtitle: 'confirmed-reservations',
          dashboardType: 'admin',
        },
        path: 'confirmed-reservations',
        component: () => import('@/views/dashboard/flyer/BookingList/BookingList'),
      },
      {
        meta: {layout: 'dashboard'},
        props: {
          subtitle: 'bookings',
          dashboardType: 'admin',
        },
        path: 'bookings',
        component: () => import('@/views/dashboard/flyer/BookingList/BookingList'),
      },
      {
        meta: {layout: 'dashboard'},
        props: {
          subtitle: 'confirmed-bookings',
          dashboardType: 'admin',
        },
        path: 'confirmed-bookings',
        component: () => import('@/views/dashboard/flyer/BookingList/BookingList'),
      },
      {
        meta: {layout: 'dashboard'},
        props: {
          subtitle: 'flycal-requests',
          dashboardType: 'admin',
        },
        path: 'flycal-requests',
        component: () => import('@/views/dashboard/admin/FlycalRequests/FlycalRequests'),
      },
      {
        meta: {layout: 'dashboard'},
        props: {
          subtitle: 'confirm manual booking',
          dashboardType: 'admin',
        },
        path: 'booking-confirmations',
        component: () => import('@/views/dashboard/admin/ManualBooking/ManualBooking'),
      },
      {
        meta: {layout: 'dashboard'},
        props: {
          subtitle: 'aircraft uploads',
          dashboardType: 'admin',
        },
        path: 'aircraft-uploads',
        component: () => import('@/views/dashboard/common/Fleet/Fleet'),
      },
      {
        meta: {layout: 'dashboard'},
        props: {
          subtitle: 'fleet',
          dashboardType: 'admin',
          fleetType: 'detail',
        },
        path: 'aircraft-uploads/:id',
        component: () => import('@/views/dashboard/common/DetailAircraft/DetailAircraft'),
      },
      {
        meta: {layout: 'dashboard'},
        props: {
          subtitle: 'fleet',
          dashboardType: 'admin',
          fleetType: 'new',
        },
        path: 'new-aircraft',
        component: () => import('@/views/dashboard/common/DetailAircraft/DetailAircraft'),
      },
      {
        meta: {layout: 'dashboard'},
        props: {
          subtitle: 'charter uploads',
          dashboardType: 'admin',
          uploadsType: 'charter',
        },
        path: 'charter-uploads',
        component: () => import('@/views/dashboard/common/CharterUploads/CharterUploads'),
      },
      {
        meta: {layout: 'dashboard'},
        props: {
          subtitle: 'flight uploads',
          dashboardType: 'admin',
          uploadsType: 'flight',
        },
        path: 'flight-uploads',
        component: () => import('@/views/dashboard/common/FlightUploads/FlightUploads'),
      },
      {
        meta: {layout: 'dashboard'},
        props: {
          subtitle: 'flyrewards uploads',
          dashboardType: 'admin',
        },
        path: 'flyrewards',
        component: () => import('@/views/dashboard/flyer/FlyRewards/FlyRewards'),
      },
      {
        meta: {layout: 'dashboard'},
        props: {
          subtitle: 'messages',
          dashboardType: 'admin',
        },
        path: 'messages',
        component: () => import('@/views/dashboard/common/Messages/Messages'),
      },
      {
        meta: {layout: 'dashboard'},
        props: {
          subtitle: 'subscription analytics',
          dashboardType: 'admin',
        },
        path: 'subscription-analytics',
        component: () => import('@/views/dashboard/admin/SubscriptionAnalytics/SubscriptionAnalytics'),
      },
    ],
  },
  {
    path: '/fly-report',
    meta: {layout: 'report'},
    props: {
      title: 'The FLY Report',
      dashboardType: 'report',
    },
    component: () => import('@/views/dashboard/DashboardContainer.vue'),
    async beforeEnter (to, from, next) {
      const check = await store.dispatch('checkLogin')

      if (check) {
        if (to.fullPath === '/fly-report') {
          next('/fly-report/articles')
        } else {
          next()
        }
      } else {
        next('/')
      }
    },
    children: [
      {
        meta: {layout: 'report'},
        props: {
          subtitle: 'The fly report',
          dashboardType: 'report',
        },
        path: 'articles',
        component: () => import('@/views/report/ArticleList/ArticleList'),
      },
      {
        meta: {layout: 'report'},
        props: {
          subtitle: 'The fly report',
          dashboardType: 'report',
        },
        path: 'articles/:id',
        component: () => import('@/views/report/Article/Article'),
      },
    ],
  },
  {
    path: '/disclaimer',
    props: {header: 'Disclaimer'},
    meta: {layout: 'common'},
    component: () => import('@/views/info/Disclaimer/Disclaimer.vue'),
  },
  {
    path: '/privacy-policy',
    props: {header: 'Privacy Policy'},
    meta: {layout: 'common'},
    component: () => import('@/views/info/PrivacyPolicy/PrivacyPolicy.vue'),
  },
  {
    path: '/terms-conditions',
    props: {header: 'Terms and Conditions'},
    meta: {layout: 'common'},
    component: () => import('@/views/info/TermsConditions/TermsConditions.vue'),
  },
  {
    path: '/about',
    props: {header: 'About us'},
    meta: {layout: 'common'},
    component: () => import('@/views/info/About/About.vue'),
  },
  {
    path: '/contact',
    props: {header: 'Contact', whatsapp: true},
    meta: {layout: 'common'},
    component: () => import('@/views/info/Contact/Contact.vue'),
  },
  {
    path: '/app/Disclaimer',
    props: {header: 'Disclaimer'},
    meta: {layout: 'common'},
    component: () => import('@/views/info/Disclaimer/Disclaimer.vue'),
  },
  {
    path: '/app/Privacy',
    props: {header: 'Privacy Policy'},
    meta: {layout: 'common'},
    component: () => import('@/views/info/PrivacyPolicy/PrivacyPolicy.vue'),
  },
  {
    path: '/app/Terms',
    props: {header: 'Terms and Conditions'},
    meta: {layout: 'common'},
    component: () => import('@/views/info/TermsConditions/TermsConditions.vue'),
  },
  {
    path: '/app/About',
    props: {header: 'About us'},
    meta: {layout: 'common'},
    component: () => import('@/views/info/About/About.vue'),
  },
  {
    path: '/app/Contact',
    props: {header: 'Contact', whatsapp: true},
    meta: {layout: 'common'},
    component: () => import('@/views/info/Contact/Contact.vue'),
  },
  {
    path: '/contact2',
    props: {header: 'Contact', whatsapp: false},
    meta: {layout: 'common'},
    component: () => import('@/views/info/Contact/Contact.vue'),
  },
  {
    path: '/theflyreport',
    props: {header: 'The fly report', headerSearch: false},
    meta: {layout: 'blog'},
    component: () => import('@/views/blog/Blog.vue'),
  },
  {
    path: '/Opinion',
    props: {header: 'The fly report', headerSearch: false},
    meta: {layout: 'blog'},
    component: () => import('@/views/blog/Blog.vue'),
  },
  {
    path: '/News',
    props: {header: 'The fly report', headerSearch: false},
    meta: {layout: 'blog'},
    component: () => import('@/views/blog/Blog.vue'),
  },
  {
    path: '/Features',
    props: {header: 'The fly report', headerSearch: false},
    meta: {layout: 'blog'},
    component: () => import('@/views/blog/Blog.vue'),
  },
  {
    path: '/theflyreport/subcategories/:id',
    props: {header: 'The fly report', headerSearch: false},
    meta: {layout: 'blog'},
    component: () => import('@/views/blog/BlogSubcategory.vue'),
  },
  {
    path: '/theflyreport/article/:id',
    props: {header: 'The fly report', headerSearch: false},
    meta: {layout: 'blog'},
    component: () => import('@/views/blog/BlogArticle.vue'),
  },
  {
    path: '/theflyreport/search',
    props: {header: 'The fly report', headerSearch: false},
    meta: {layout: 'blog'},
    component: () => import('@/views/blog/BlogSearch.vue'),
  },
  {
    path: '/theflyreport/argus',
    props: {header: 'The fly report', headerSearch: false},
    meta: {layout: 'blog'},
    component: () => import('@/views/blog/BlogArgusMonitor'),
  },
  {
    path: '/home-page',
    component: () => import('@/views/main/HomePage/HomePage.vue'),
  },
  {
    path: '*',
    component: () => import('@/views/main/HomePage/HomePage.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
