<template>
  <div class="fixPriceRouteDashboard" :class="'fixPriceRouteDashboard--' + dashboardType">
    <div v-if="dashboardType === 'provider'" class="bookingDashboard bookingDashboard--list">
      <div class="bookingDashboard__header bookingDashboard__header--provider">
        <div class="bookingDashboard__title">Fixed price routes</div>
        <router-link class="buttonCreateFixedPriceRoute" to="new-fixed-price-route">Create</router-link>
      </div>
      <div class="bookingDashboard__table">
        <div class="bookingDashboard__table-header">
          <div class="bookingDashboard__table-cell">№</div>
          <div class="bookingDashboard__table-cell">Model</div>
          <div class="bookingDashboard__table-cell">First Airport</div>
          <div class="bookingDashboard__table-cell">Second Airport</div>
          <div class="bookingDashboard__table-cell">Price</div>
        </div>
        <div class="bookingDashboard__table-body">
          <vue-scroll :ops="ops">
            <div
              class="bookingDashboard__table-row"
              v-for="(item, index) in fixPriceRoute"
              :key="item.id"
            >
              <div class="bookingDashboard__table-cell">{{index + 1}}</div>
              <div class="bookingDashboard__table-cell">{{item.aircraftModelName}}</div>
              <div class="bookingDashboard__table-cell">{{item.firstAirportName}}</div>
              <div class="bookingDashboard__table-cell">{{item.secondAirportName}}</div>
              <div class="bookingDashboard__table-cell">
                {{item.price}}
                <router-link class="bookingDashboard__table-detail" :to="'fixed-price-routes/'+ item.id">edit</router-link>
              </div>
            </div>
          </vue-scroll>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { Tabs } from '@/js/plugins/tabs'
  import './FixPriceRoutes.styl'

  export default {
    props: ['dashboardType'],
    name: 'FixPriceRoute',
    data: () => ({
      tabs: new Tabs('.js-tabNavItem', '.js-tabContentItem'),
      ops: {
        rail: {
          size: '3px',
        },
        bar: {
          size: '3px',
          background: 'rgba(0,0,0,0.1)',
        },
      },
    }),
    computed: {
      fixPriceRoute () {
        return this.$store.state.flyAlliance.fixPriceRouteList
      },
    },
    async created () {
      await this.$store.dispatch('getFixPriceRoutes')
    },
    mounted () {
      this.tabs.addActiveForMouted()
    },
    methods: {
      tabActivate (event) {
        this.tabs.addActiveForClick(event.currentTarget)
      },
    },
  }
</script>
