import { instance } from './index';

export const flyAllianceAPI = {
    getFixPriceRoute () {
      return instance.get('fly-alliance/fixed-price-route')
    },
    getCurrentFixPriceRoute (id) {
      return instance.get(`fly-alliance/fixed-price-route/${id}`)
    },
    createFixPriceRoute (data) {
      return instance.post('fly-alliance/fixed-price-route', data)
    },
    updateFixPriceRoute (data) {
      return instance.patch('fly-alliance/fixed-price-route', data)
    },
    fixPriceRouteRemove (id) {
      return instance.delete(`fly-alliance/fixed-price-route/remove/${id}`)
    },
}