<template>
  <div
    class="fixPriceRouteDashboard"
    :class="[
      {'fixPriceRouteDashboard--detail' : fixPriceRouteType === 'detail'},
      {'fixPriceRouteDashboard--new' : fixPriceRouteType === 'new'},
      {'fixPriceRouteDashboard--provider' : dashboardType === 'provider'}
      ]"
  >
    <vue-scroll :ops="ops">
      <div v-if="fixPriceRouteType === 'detail'" class="fixPriceRouteDashboard__title">Fixed Price Route</div>
      <div v-else class="fixPriceRouteDashboard__title">New Fixed Price Route</div>
      <div class="fixPriceRouteDashboard__form" :class="'fixPriceRouteDashboard__form--'">
        <div class="form form--fleet js-fleetForm">
            <div class="form__labelGroup form__labelGroup--fixPriceRoute">
            <label class="form__label form__label--select">
              <div class="form__caption">Aircraft model</div>
              <div class="form__field-wrapper">
                <input
                  class="form__field js-typeahead"
                  type="text"
                  data-type="aircraftModel"
                  @input="flyAllianceSearchResults"
                  @focus="showTypeheadMenu('aircraftModel')"
                  @blur="hideTypeheadMenu"
                  v-model="fixPriceRouteData.model"
                  :disabled="loading"
                />
                <div
                  class="typeahead-menu typeahead-empty"
                  :class="{'typeahead-open' : typeheadMenu === 'aircraftModel'}"
                >
                  <div class="typeahead-dataset typeahead-dataset-types">
                    <div
                      class="typeahead-suggestion typeahead-selectable"
                      data-type="select-aircraftModel"
                      v-for="(item, index) of shortListAircraftModel" :key="index"
                      @click="selectAircraftOptions(item)"
                    >{{ item.name }}
                    </div>
                  </div>
                </div>
              </div>
              <span v-if="$v.fixPriceRouteData.model.$dirty && !$v.fixPriceRouteData.model.required" class="form__field-invalid">
                This field is required
              </span>
            </label>
            <label class="form__label form__label--select">
              <div class="form__caption">Price:</div>
              <div class="form__field-wrapper">
                <input
                  class="form__field"
                  type="text"
                  v-model="fixPriceRouteData.price"
                  v-mask="'########'"
                  :disabled="loading"
                  autocomplete="off"
                />
              </div>
              <span v-if="$v.fixPriceRouteData.price.$dirty && !$v.fixPriceRouteData.price.required"
                    class="form__field-invalid">
                This field is required
              </span>
            </label>
            <label class="form__label form__label--select">
                <div class="form__caption">First Airport:</div>
                <div class="form__field-wrapper">
                    <input
                    class="form__field js-typeahead"
                    type="text"
                    data-type="firstAirport"
                    @input="flyAllianceSearchResults"
                    @focus="showTypeheadMenu('firstAirport')"
                    @blur="hideTypeheadMenu"
                    v-model="fixPriceRouteData.firstAirport"
                    :disabled="loading"
                    />
                    <div
                    class="typeahead-menu typeahead-empty"
                    :class="{'typeahead-open' : typeheadMenu === 'firstAirport'}"
                    >
                    <div class="typeahead-dataset typeahead-dataset-types">
                        <div
                        class="typeahead-suggestion typeahead-selectable"
                        data-type="select-firstAirport"
                        v-for="(item, index) of shortListFirstAirport" :key="index"
                        @click="selectAircraftOptions(item)"
                        >{{ item.name }}
                        </div>
                    </div>
                    </div>
                </div>
                <span v-if="$v.fixPriceRouteData.firstAirport.$dirty && !$v.fixPriceRouteData.firstAirport.required"
                    class="form__field-invalid">
                            This field is required
                </span>
            </label>
            <label class="form__label form__label--select">
                <div class="form__caption">Second Airport:</div>
                <div class="form__field-wrapper">
                    <input
                    class="form__field js-typeahead"
                    type="text"
                    data-type="secondAirport"
                    @input="flyAllianceSearchResults"
                    @focus="showTypeheadMenu('secondAirport')"
                    @blur="hideTypeheadMenu"
                    v-model="fixPriceRouteData.secondAirport"
                    :disabled="loading"
                    />
                    <div
                    class="typeahead-menu typeahead-empty"
                    :class="{'typeahead-open' : typeheadMenu === 'secondAirport'}"
                    >
                    <div class="typeahead-dataset typeahead-dataset-types">
                        <div
                        class="typeahead-suggestion typeahead-selectable"
                        data-type="select-secondAirport"
                        v-for="(item, index) of shortListSecondAirport" :key="index"
                        @click="selectAircraftOptions(item)"
                        >{{ item.name }}
                        </div>
                    </div>
                    </div>
                </div>
                <span v-if="$v.fixPriceRouteData.secondAirport.$dirty && !$v.fixPriceRouteData.secondAirport.required"
                    class="form__field-invalid">
                            This field is required
                </span>
            </label>
          </div>
          <div style="display: flex">
            <div class="form__label form__label--button">
              <button class="form__button  form__button--submit"
              :disabled="loading"
              @click="saveRoute">
                <span class="text">Save route</span>
              </button>
            </div>
            <div v-if="fixPriceRouteType === 'detail'" class="form__label form__label--button">
              <button
                class="form__button  form__button--submit"
                type="button"
                :disabled="loading"
                @click="showModal"
              >
                <span class="text">Remove route</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </vue-scroll>
    <QuestionPopup
      :name="'QuestionPopup'"
      :title="'The route will be removed'"
      :text="'Are you sure?'"
      @remove="deleteFixPriceRoute"
    />
  </div>
</template>

<script>
import {flyAllianceAPI} from '@/api/flyAlliance'
import QuestionPopup from '@/components/common/modals/QuestionPopup'
import {required} from 'vuelidate/lib/validators'
import './DetailFixPriceRoute.styl'
import '@/components/common/SelectCommon/SelectCommon.styl'

export default {
  props: ['dashboardType', 'fixPriceRouteType'],
  name: 'DetailFixPriceRoute',
  data: () => ({
    activeSlide: 1,
    toggler: true,
    swiper: null,
    ops: {
      scrollPanel: {
        initialScrollX: false,
        scrollingX: false,
      },
      rail: {
        size: '3px',
      },
      bar: {
        size: '3px',
        background: 'rgba(0,0,0,0.1)',
      },
    },
    edit: false,
    loading: false,
    fixPriceRouteData: {
      id: null,
      model: '',
      modelId: '',
      firstAirport: '',
      firstAirportId: '',
      secondAirport: '',
      secondAirportId: '',
      price: '',
    },
    timeout: null,
    typeheadMenu: null,
  }),
  validations: {
    fixPriceRouteData: {
      model: {required},
      firstAirport: {required},
      secondAirport: {required},
      price: {required},
    },
  },
  computed: {
    shortListAircraftModel () {
      return this.$store.getters.shortListAircraftModels(4)
    },
    shortListFirstAirport () {
      return this.$store.getters.shortListFirstAirport(4)
    },
    shortListSecondAirport () {
      return this.$store.getters.shortListSecondAirport(4)
    },
  },
  async created () {
    if (this.fixPriceRouteType === 'detail') {
      const fixPriceRouteId = this.$route.params.id

      try {
        const result = await flyAllianceAPI.getCurrentFixPriceRoute(fixPriceRouteId);
        if (result.data.isSuccessfull) {
            const {data: {item}} = result;
            this.fixPriceRouteData.id = item[0].id
            this.fixPriceRouteData.model = item[0].aircraftModelName
            this.fixPriceRouteData.modelId = item[0].aircraftModelId
            this.fixPriceRouteData.firstAirport = item[0].firstAirportName
            this.fixPriceRouteData.firstAirportId = item[0].firstAirportId
            this.fixPriceRouteData.secondAirport = item[0].secondAirportName
            this.fixPriceRouteData.secondAirportId = item[0].secondAirportId
            this.fixPriceRouteData.price = item[0].price
        } else {
            console.log(result.data.message)
        }
      } catch (e) {
        console.log(e);
      }
    }
  },
  methods: {
    async flyAllianceSearchResults () {
      const dataType = event.currentTarget.dataset.type
      let query
      if (dataType === 'aircraftModel') {
        query = this.fixPriceRouteData.model
      } else if (dataType === 'firstAirport') {
        query = this.fixPriceRouteData.firstAirport
      } else {
        query = this.fixPriceRouteData.secondAirport
      }

      const that = this

      if (this.timeout != null) {
        clearTimeout(this.timeout)
      }

      this.timeout = setTimeout(async function () {
        this.timeout = null

        const data = {
          query: query,
          type: dataType,
        }

        if (data.query) {
          await that.$store.dispatch('flyAllianceSearchResults', data)
          if (dataType === 'aircraftModel') {
            that.showTypeheadMenu('aircraftModel')
          } else if (dataType === 'firstAirport') {
            that.showTypeheadMenu('firstAirport')
          } else {
            that.showTypeheadMenu('secondAirport')
          }
        } else {
          that.$store.commit('deleteFlyAllianceSearchResults', data)
          that.typeheadMenu = null

          if (dataType === 'aircraftModel') {
            that.fixPriceRouteData.modelId = ''
          } else if (dataType === 'firstAirport') {
            that.fixPriceRouteData.firstAirportId = ''
          } else {
            that.fixPriceRouteData.secondAirportId = ''
          }
        }
      }, 500)
    },
    showTypeheadMenu (type) {
      if (type === 'aircraftModel' && this.shortListAircraftModel.length) {
        this.typeheadMenu = 'aircraftModel'
      } else if (type === 'firstAirport' && this.shortListFirstAirport.length) {
        this.typeheadMenu = 'firstAirport'
      } else if (type === 'secondAirport' && this.shortListSecondAirport.length) {
        this.typeheadMenu = 'secondAirport'
      } else {
        this.typeheadMenu = null
      }
    },
    hideTypeheadMenu () {
      const that = this
      setTimeout(function () {
        that.typeheadMenu = null
      }, 200)
    },
    selectAircraftOptions (item) {
      const that = this
      if (event.target.getAttribute('data-type') === 'select-aircraftModel') {
        this.fixPriceRouteData.modelId = item.id
        this.fixPriceRouteData.model = item.name
      } else if (event.target.getAttribute('data-type') === 'select-firstAirport') {
        this.fixPriceRouteData.firstAirportId = item.id
        this.fixPriceRouteData.firstAirport = item.name
      } else {
        this.fixPriceRouteData.secondAirportId = item.id
        this.fixPriceRouteData.secondAirport = item.name
      }

      setTimeout(function () {
        that.typeheadMenu = null
      }, 200)
    },
    async saveRoute () {
        if (
        this.$v.fixPriceRouteData.model.$invalid ||
        this.$v.fixPriceRouteData.firstAirport.$invalid ||
        this.$v.fixPriceRouteData.secondAirport.$invalid ||
        this.$v.fixPriceRouteData.price.$invalid
      ) {
        this.$v.$touch()
        return false
      }
      this.loading = true

      let result
      if (this.fixPriceRouteType === 'new') {
        result = await flyAllianceAPI.createFixPriceRoute({
            price: this.fixPriceRouteData.price,
            firstAirportId: this.fixPriceRouteData.firstAirportId,
            secondAirportId: this.fixPriceRouteData.secondAirportId,
            aircraftModelId: this.fixPriceRouteData.modelId,
        })
      } else {
        result = await flyAllianceAPI.updateFixPriceRoute({
            id: this.fixPriceRouteData.id,
            price: this.fixPriceRouteData.price,
            firstAirportId: this.fixPriceRouteData.firstAirportId,
            secondAirportId: this.fixPriceRouteData.secondAirportId,
            aircraftModelId: this.fixPriceRouteData.modelId,
        })
      }

      this.loading = false
      if (result.data.isSuccessfull) {
            let message = {
              title: 'Route Created',
              text: 'Fixed price route has been created successfully',
            };

            if (this.fixPriceRouteType === 'detail') {
              message = {
                title: 'Route Saved',
                text: 'Fixed price route has been saved successfully',
              };
            }

            this.$modal.show('ThanksPopup', {message})
            this.$router.push('/provider-dashboard/fixed-price-routes')
      } else {
            console.log(result.data.message)
            const message = {
              title: 'Error',
              text: 'Something went wrong',
            }
            this.$modal.show('ThanksPopup', {message})
      }
    },
    showModal () {
      this.$modal.show('QuestionPopup')
    },
    async deleteFixPriceRoute () {
      if (this.fixPriceRouteType === 'detail') {
        this.loading = true
        try {
          await flyAllianceAPI.fixPriceRouteRemove(this.fixPriceRouteData.id)

          this.$router.push('/provider-dashboard/fixed-price-routes')
        } catch (e) {
          console.log(e)
          const message = {
            title: 'Error',
            text: 'Something went wrong',
          }
          this.$modal.show('ThanksPopup', {message})
        }

        this.loading = false
      }
    },
  },
  components: {
    QuestionPopup,
  },
  metaInfo: {
    meta: [{
       name: 'robots', content: 'noindex',
    }],
  },
}
</script>
